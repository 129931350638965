import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO />

    <div className="row">
      <div className="twelve columns main-page">
        <Link to="/barcelona/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Barcelona</h1>
              <p>Last stop in our 5 month Europe trip</p>
              <date>
                Sep 21<sup>th</sup> - Sep 27<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/barcelona/barcelona.webp" type="image/webp" />
            <img
              src="/barcelona/barcelona.jpg"
              alt="barcelona photoset"
              width="1400"
            />
          </picture>
        </Link>
        <Link to="/lisbon/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Lisbon</h1>
              <p>Sixth stop in our 5 month Europe trip</p>
              <date>
                Sep 19<sup>th</sup> - Sep 21<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/lisbon/lisbon.webp" type="image/webp" />
            <img src="/lisbon/lisbon.jpg" alt="lisbon photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/amsterdam/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Amsterdam</h1>
              <p>Fifth stop in our 5 month Europe trip</p>
              <date>
                Sep 13<sup>th</sup> - Sep 16<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/amsterdam/amsterdam.webp" type="image/webp" />
            <img
              src="/amsterdam/amsterdam.jpg"
              alt="amsterdam photoset"
              width="1400"
            />
          </picture>
        </Link>
        <Link to="/paris/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Paris</h1>
              <p>Fourth stop in our 5 month Europe trip</p>
              <date>
                Sep 8<sup>th</sup> - Sep 13<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/paris/paris.webp" type="image/webp" />
            <img src="/paris/paris.jpg" alt="paris photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/venice/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Venice</h1>
              <p>Third stop in our 5 month Europe trip</p>
              <date>
                Sep 2<sup>nd</sup> - Sep 6<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/venice/venice.webp" type="image/webp" />
            <img src="/venice/venice.jpg" alt="venice photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/florence/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Florence</h1>
              <p>
                A train stop on our way from Rome to Venice in our 5 month
                Europe trip
              </p>
              <date>
                Sep 2<sup>nd</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/florence/florence.webp" type="image/webp" />
            <img
              src="/florence/florence.jpg"
              alt="florence photoset"
              width="1400"
            />
          </picture>
        </Link>
        <Link to="/rome/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Rome</h1>
              <p>Second stop in our 5 month Europe trip</p>
              <date>
                Aug 30<sup>th</sup> - Sep 2<sup>nd</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/rome/rome.webp" type="image/webp" />
            <img src="/rome/rome.jpg" alt="Rome photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/athens/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Athens</h1>
              <p>First stop in our 5 month Europe trip</p>
              <date>
                Aug 25<sup>th</sup> - Aug 30<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/athens/athens.webp" type="image/webp" />
            <img src="/athens/athens.jpg" alt="Athens photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/oaxaca/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Oaxaca</h1>
              <p>A week in Oaxaca</p>
              <date>
                Mar 20<sup>th</sup> - Mar 31<sup>th</sup>, &nbsp;2019
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/oaxaca/oaxaca.webp" type="image/webp" />
            <img src="/oaxaca/oaxaca.jpg" alt="oaxaca photoset" width="1400" />
          </picture>
        </Link>
        <Link to="/south-island/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>South Island</h1>
              <p>
                Our final trip to New Zealand before we moved back to Mexico
              </p>
              <date>
                Feb 28<sup>th</sup> - March 20<sup>th</sup>, &nbsp;2017
              </date>
            </div>
          </div>
          <picture>
            <source
              srcSet="/south-island/south-island.webp"
              type="image/webp"
            />
            <img
              src="/south-island/south-island.jpg"
              alt="South Island New Zealand photoset"
              width="1400"
            />
          </picture>
        </Link>

        <Link to="/great-ocean-road/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Great Ocean Road</h1>
              <p>A weekend road trip along the Great Ocean Road</p>
              <date>
                Dec 16<sup>th</sup> - Dec 18<sup>th</sup>, &nbsp;2016
              </date>
            </div>
          </div>
          <picture>
            <source
              srcSet="/great-ocean-road/great-ocean-road.webp"
              type="image/webp"
            />
            <img
              src="/great-ocean-road/great-ocean-road.jpg"
              alt="Great Ocean Road photoset"
              width="1400"
            />
          </picture>
        </Link>

        <Link to="/cambodia/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Cambodia</h1>
              <p>
                A 2 month adventure made of nice people, wrapped in humidity and
                heat, and covered by a magical realism.
              </p>
              <date>
                Dec 16<sup>th</sup> - Feb 28<sup>th</sup>, &nbsp;2015/2016
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/cambodia/cambodia.webp" type="image/webp" />
            <img
              src="/cambodia/cambodia.jpg"
              alt="Cambodia photoset"
              width="1400"
            />
          </picture>
        </Link>

        <Link to="/nelson/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Nelson</h1>
              <p>
                Visting my parents in Nelson for New years, and traveling to the
                Abel Tasman National Park.
              </p>
              <date>
                Dec 31<sup>st</sup> - Jan 11<sup>th</sup>, &nbsp;2017
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/nelson/nelson.webp" type="image/webp" />
            <img src="/nelson/nelson.jpg" alt="Nelson photoset" width="1400" />
          </picture>
        </Link>

        <Link to="/gold-coast/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Gold Coast</h1>
              <p>
                A family holiday to the gold coast to enjoy the sun and beaches.
              </p>
              <date>
                Nov 25<sup>th</sup> - Dec 1<sup>st</sup>, &nbsp;2016
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/gold-coast/gold-coast.webp" type="image/webp" />
            <img
              src="/gold-coast/gold-coast.jpg"
              alt="Gold Coast photoset"
              width="1400"
            />
          </picture>
        </Link>

        <Link to="/tokyo/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Tokyo</h1>
              <p>
                Our Summer fling with Japan!
                <br />
                We spent 10 days in Tokyo to escape the winter.
              </p>
              <date>
                July 10<sup>th</sup> - July 20<sup>th</sup>, &nbsp;2015
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/tokyo/tokyo.webp" type="image/webp" />
            <img src="/tokyo/tokyo.jpg" alt="Tokyo photoset" width="1400" />
          </picture>
        </Link>

        <Link to="/sydney/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Sydney</h1>
              <p>
                A quick Easter trip to Sydney for Ivette's first holiday week.
                <br />
                Opera house's, bridges and Sun.
              </p>
              <date>
                April 4<sup>th</sup> - April 8<sup>th</sup>, &nbsp;2015
              </date>
            </div>
          </div>
          <picture>
            <source srcSet="/sydney/sydney.webp" type="image/webp" />
            <img src="/sydney/sydney.jpg" alt="Sydney photoset" width="1400" />
          </picture>
        </Link>

        <Link to="/otago/" className="photo-group">
          <div className="details-outer">
            <div className="details">
              <h1>Dunedin, and Central Otago</h1>
              <p>
                After being home for christmas Ivette came from Mexico,
                <br />
                to explore New Zealand before we left to Melbourne.
              </p>
              <date>
                Jan 28<sup>th</sup> - Feb 15<sup>th</sup>, &nbsp;2015
              </date>
            </div>
          </div>
          <picture>
            <source
              srcSet="/dunedin-and-central-otago/dunedin-and-central-otago.webp"
              type="image/webp"
            />
            <img
              src="/dunedin-and-central-otago/dunedin-and-central-otago.jpg"
              alt="New Zealand photoset"
              width="1400"
            />
          </picture>
        </Link>
      </div>
    </div>
  </Layout>
)
